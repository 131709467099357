<template>
  <div>
    <van-cell>
      <template slot="title">
        <van-switch
          id="sexSwitch"
          v-model="checked"
          size="24"
          active-color="#57c4b7"
          inactive-color="#57c4b7"
          @change="changeSex"
        ></van-switch>
      </template>
      <template slot="right-icon">
        <van-switch
          id="ageSwitch"
          v-model="checked1"
          size="24"
          active-color="#57c4b7"
          inactive-color="#57c4b7"
          @change="changeAge"
        ></van-switch>
      </template>
    </van-cell>
    <van-cell center>
      <template slot="title">
        <div style="font-size:12px; line-height:24px; color:red">是否跳过智能导诊,直接跳转到科室页面挂号?</div>
      </template>
      <template slot="right-icon">
        <div style="font-size:12px; line-height:24px; margin-left:1%; color:#57c4b7">
          <van-button round plain color="#57c4b7" size="mini" @click="onSkip">跳过</van-button>
        </div>
      </template>
    </van-cell>
    <div id="adultDiv">
      <van-image width="60%" height="40%" :src="img" style="margin-left:20%;" />
      <van-grid :column-num="3" style="position: static; ">
        <van-grid-item
          style="width:35%; height: 18%; position: absolute; top: 0; opacity:0; margin-top:90px"
        />
        <van-grid-item
          style="width:30%; height: 18%; position: absolute; top: 0; left:35%; opacity:0; margin-top:90px"
          @click="showPopup(0)"
        />
        <van-grid-item
          style="width:35%; height: 18%; position: absolute; top: 0; left:65%; opacity:0; margin-top:90px "
        />
      </van-grid>
      <van-grid :column-num="5" style="position: static; ">
        <van-grid-item
          style="width:15%; height: 40%; position: absolute; top: 18%; opacity:0; margin-top:90px"
        />
        <van-grid-item
          style="width:20%; height: 40%; position: absolute; top: 18%; left:15%; opacity:0; margin-top:90px"
          @click="showPopup(4)"
        />
        <van-grid-item
          style="width:30%; height: 40%; position: absolute; top: 18%; left:35%; opacity:0;margin-top:90px"
        >
          <div style="width:100%; height: 30%;" @click="showPopup(1)"></div>
          <div style="width:100%; height: 40%;" @click="showPopup(2)"></div>
          <div style="width:100%; height: 30%;" @click="showPopup(3)"></div>
        </van-grid-item>
        <van-grid-item
          style="width:20%; height: 40%; position: absolute; top: 18%; left:65%; opacity:0; margin-top:90px "
          @click="showPopup(4)"
        />
        <van-grid-item
          style="width:15%; height: 40%; position: absolute; top: 18%; opacity:0; margin-top:90px"
        />
      </van-grid>
      <van-grid :column-num="3" style="position: static; ">
        <van-grid-item
          style="width:35%; height: 40%; position: absolute; top: 58%; opacity:0; margin-top:90px"
        />
        <!-- <van-grid-item style="width:20%; height: 40%; position: absolute; top: 18%; left:15%; opacity:0; margin-top:90px"/> -->
        <van-grid-item
          style="width:30%; height: 40%; position: absolute; top: 58%; left:35%; opacity:0;margin-top:90px"
          @click="showPopup(5)"
        />
        <van-grid-item
          style="width:35%; height: 40%; position: absolute; top: 58%; left:65%; opacity:0; margin-top:90px "
        />
        <!-- <van-grid-item style="width:15%; height: 40%; position: absolute; top: 18%; opacity:0; margin-top:90px" /> -->
      </van-grid>
      <div style="text-align:right;margin: 0 20px 50px 0;">
        <van-button
          color="#57c4b7"
          style="width:60px; height: 60px; border-radius:50%;"
          @click="BackFace"
        >转身</van-button>
      </div>
    </div>

    <div id="childerDiv">
      <van-image width="60%" height="40%" :src="img" style="margin-left:20%;" />
      <van-grid :column-num="3" style="position: static; ">
        <van-grid-item
          style="width:30%; height: 28%; position: absolute; top: 0; opacity:0; margin-top:90px"
        />
        <van-grid-item
          style="width:42%; height: 28%; position: absolute; top: 0; left:30%; opacity:0; margin-top:90px"
          @click="onChilderClick"
        />
        <van-grid-item
          style="width:28%; height: 28%; position: absolute; top: 0; left:72%; opacity:0;  margin-top:90px "
        />
      </van-grid>
      <van-grid :column-num="5" style="position: static; ">
        <van-grid-item
          style="width:15%; height: 25%; position: absolute; top: 28%; opacity:0; margin-top:90px"
        />
        <van-grid-item
          style="width:20%; height: 25%; position: absolute; top: 28%; left:15%; opacity:0; margin-top:90px"
          @click="onChilderClick"
        />
        <van-grid-item
          style="width:30%; height: 25%; position: absolute; top: 28%; left:35%; opacity:0;margin-top:90px"
        >
          <div style="width:100%; height: 30%;" @click="onChilderClick"></div>
          <div style="width:100%; height: 40%;" @click="onChilderClick"></div>
          <div style="width:100%; height: 30%;" @click="onChilderClick"></div>
        </van-grid-item>
        <van-grid-item
          style="width:20%; height: 25%; position: absolute; top: 28%; left:65%; opacity:0; margin-top:90px "
          @click="onChilderClick"
        />
        <van-grid-item
          style="width:15%; height: 25%; position: absolute; top: 28%; opacity:0; margin-top:90px"
        />
      </van-grid>
      <van-grid :column-num="3" style="position: static; ">
        <van-grid-item
          style="width:35%; height: 25%; position: absolute; top: 53%; opacity:0; margin-top:90px"
        />
        <!-- <van-grid-item style="width:20%; height: 40%; position: absolute; top: 18%; left:15%; opacity:0; margin-top:90px"/> -->
        <van-grid-item
          style="width:30%; height: 25%; position: absolute; top: 53%; left:35%; opacity:0;margin-top:90px"
          @click="onChilderClick"
        />
        <van-grid-item
          style="width:35%; height: 25%; position: absolute; top: 53%; left:65%; opacity:0; margin-top:90px "
        />
        <!-- <van-grid-item style="width:15%; height: 40%; position: absolute; top: 18%; opacity:0; margin-top:90px" /> -->
      </van-grid>
      <div style="text-align:right;margin: 0 20px 50px 0;">
        <van-button
          color="#57c4b7"
          style="width:60px; height: 60px; border-radius:50%;"
          @click="BackFace"
        >转身</van-button>
      </div>
    </div>

    <van-popup id="popup" v-model="show" position="right" :style="{ height: '100%',width:'70%' }">
      <p style="text-align:center; line-height:36px; color:#57c4b7">病理部位</p>
      <van-cell-group v-for="item in positionList" :key="item.index">
        <van-cell :title="item.buwei" is-link @click="onSymptom(item.dep)" />
        <!-- <van-collapse v-model="activeNames">
                <van-collapse-item  title="头颅" name="1">
                    <van-cell title="头晕" is-link @click="onSymptom" style="padding:0; color:#57c4b7; font-size:12px; margin-bottom: 2px;"/>
                </van-collapse-item>
            <van-collapse-item title="眼部" name="2">内容</van-collapse-item>
            <van-collapse-item title="耳鼻" name="3">内容</van-collapse-item> 
        </van-collapse>-->
      </van-cell-group>
    </van-popup>
    <div style="font-size: 12px; color: #D3D3D3; margin: 1% 40%; width: auto; height: 40px;">
      <span>&copy;拓实银医</span>
    </div>
  </div>
</template>

<script>
import store from "../../store";
export default {
  data() {
    return {
      checked: true,
      checked1: true,
      sexState: "正面",
      show: false,
      imageList: [
        require("../../assets/男生.png"),
        require("../../assets/男生反.png"),
        require("../../assets/女生.png"),
        require("../../assets/女生反.png"),
        require("../../assets/小娃男.png"),
        require("../../assets/小娃男背面.png"),
        require("../../assets/小娃女.png"),
        require("../../assets/小娃女背面.png"),
      ],
      img: "",
      imgTip: 0,
      activeNames: [],
      positionList: [],
      toubuList: [{
						buwei: '头部',
						dep: [{
								question: '是否有头晕头痛的症状?',
								depInfo: [{
									depID: '0163',
									depName: '门诊内科'
								}]
							},
							{
								question: '是否有发烧发热的症状?',
								depInfo: [{
									depID: '0237',
									depName: '急诊科'
								}, {
									depID: '0163',
									depName: '门诊内科'
								}]
							},
							{
								question: '是否有肿块、外伤?',
								depInfo: [{
									depID: '0220',
									depName: '门诊外一科'
								}]
							}
						]
					},
					{
						buwei: '眼部',
						dep: [{
							question: '是否有近视、远视、有异物的症状?',
							depInfo: [{
								depID: '0229',
								depName: '门诊眼科'
							}]
						}]
					},
					{
						buwei: '耳部',
						dep: [{
							question: '是否有听觉下降、听力障碍的症状?',
							depInfo: [{
								depID: '0161',
								depName: '门诊五官科'
							}]
						}]
					},
					{
						buwei: '鼻子',
						dep: [{
								question: '是否有流鼻涕、打喷嚏的症状?',
								depInfo: [{
									depID: '0237',
									depName: '急诊科'
								}]
							},
							{
								question: '是否有鼻塞的症状?',
								depInfo: [{
									depID: '0163',
									depName: '门诊内科'
								}]
							}
						]
					},
					{
						buwei: '口腔',
						dep: [{
								question: '是否有流鼻涕、打喷嚏的症状?',
								depInfo: [{
									depID: '0237',
									depName: '急诊科'
								}]
							},
							{
								question: '是否有蛀牙、牙缺失、口腔正畸的症状?',
								depInfo: [{
									depID: '0161',
									depName: '门诊五官科'
								}]
							}
						]
					},
					{
						buwei: '脖子',
						dep: [{
								question: '是否有咽部不适、吞咽困难的症状?',
								depInfo: [{
									depID: '0237',
									depName: '急诊科'
								}]
							},
							{
								question: '是否有颈椎发痛的症状?',
								depInfo: [{
									depID: '0244',
									depName: '门诊外二科'
								}]
							}
						]
					}
				],
				houtopbuList: [{
					buwei: '头部',
					dep: [{
							question: '是否有头晕头痛的症状?',
							depInfo: [{
								depID: '0163',
								depName: '门诊内科'
							}]
						},
						{
							question: '是否有发烧发热的症状?',
							depInfo: [{
								depID: '0237',
								depName: '急诊科'
							}, {
								depID: '0163',
								depName: '门诊内科'
							}]
						},
						{
							question: '是否有肿块、外伤?',
							depInfo: [{
								depID: '0220',
								depName: '门诊外一科'
							}]
						}
					]
				}],
				xiongbuList: [{
					buwei: '胸部',
					dep: [{
							question: '是否有咳嗽、咯痰、哮喘的症状?',
							depInfo: [{
								depID: '0163',
								depName: '门诊内科'
							}]
						},
						{
							question: '是否有呼吸困难、胸痛、心脏不适的症状?',
							depInfo: [{
								depID: '0237',
								depName: '急诊科'
							}]
						},
						{
							question: '是否有乳房肥大的症状?',
							depInfo: [{
								depID: '0220',
								depName: '门诊外一科'
							}]
						},
						{
							question: '是否有心脏不适的症状?',
							depInfo: [{
								depID: '0163',
								depName: '门诊内科'
							}]
						}
					]
				}],
				topfubuListMan: [{
					buwei: '上腹部',
					dep: [{
							question: '是否有腹胀痛、腹泻、反酸呕吐、嗳气的症状?',
							depInfo: [{
								depID: '0163',
								depName: '门诊内科'
							}, {
								depID: '0237',
								depName: '急诊科'
							}]
						},
						{
							question: '是否有腹部肿块的症状?',
							depInfo: [{
								depID: '0220',
								depName: '门诊外一科'
							}]
						}
					]
				}],
				topfubuListWoman: [{
					buwei: '上腹部',
					dep: [{
							question: '是否有腹胀痛、腹泻、反酸呕吐、嗳气的症状?',
							depInfo: [{
								depID: '0163',
								depName: '门诊内科'
							}]
						},
						{
							question: '是否有腹部肿块的症状?',
							depInfo: [{
								depID: '0220',
								depName: '门诊外一科'
							}]
						},
						{
							question: '是否处于妊娠期?',
							depInfo: [{
								depID: '0218',
								depName: '门诊妇产科'
							}]
						}
					]
				}],
				bottomfubuListMan: [{
					buwei: '下腹部',
					dep: [{
							question: '是否在会阴部不适、胀痛的症状?',
							depInfo: [{
								depID: '0220',
								depName: '门诊外一科'
							}]
						},
						{
							question: '是否泌尿系统出现问题?',
							depInfo: [{
								depID: '0220',
								depName: '门诊外一科'
							}]
						},
						{
							question: '是否生殖系统出现问题?',
							depInfo: [{
								depID: '0220',
								depName: '门诊外一科'
							}]
						}
					]
				}],
				bottomfubuListWoman: [{
					buwei: '下腹部',
					dep: [{
							question: '是否存在会阴部不适、胀痛的症状?',
							depInfo: [{
								depID: '0218',
								depName: '门诊妇产科'
							}]
						},
						{
							question: '是否泌尿系统出现问题?',
							depInfo: [{
								depID: '0220',
								depName: '门诊外一科'
							}]
						},
						{
							question: '是否生殖系统出现问题?',
							depInfo: [{
								depID: '0218',
								depName: '门诊妇产科'
							}]
						}
					]
				}],
				topziList: [{
					buwei: '上肢',
					dep: [{
							question: '是否存在外伤?',
							depInfo: [{
								depID: '0244',
								depName: '门诊外二科'
							}]
						},
						{
							question: '是否存在骨伤?',
							depInfo: [{
								depID: '0244',
								depName: '门诊外二科'
							}]
						},
						{
							question: '是否有肢体无力的症状?',
							depInfo: [{
								depID: '0237',
								depName: '急诊科'
							}]
						}
					]
				}],
				bottomList: [{
					buwei: '下肢',
					dep: [{
							question: '是否存在外伤?',
							depInfo: [{
								depID: '0244',
								depName: '门诊外二科'
							}]
						},
						{
							question: '是否存在骨伤?',
							depInfo: [{
								depID: '0244',
								depName: '门诊外二科'
							}]
						},
						{
							question: '是否有肢体无力的症状?',
							depInfo: [{
								depID: '0237',
								depName: '急诊科'
							}]
						}
					]
				}]
    };
  },
  mounted() {
    this.getsexTxt();
    this.getageTxt();
  },
  methods: {
    onSkip() {
      this.$router
        .replace({
          path: "/IDepList",
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getsexTxt() {
      var sexSwitch = document.getElementById("sexSwitch");
      var dom = document.createElement("span");
      dom.style = "font-size: 14px; margin:0 10%; color:white;";
      dom.innerHTML = "男";
      sexSwitch.appendChild(dom);
      var dom1 = document.createElement("span");
      dom1.style = "font-size: 14px; margin:0 10%;color:white;";
      dom1.innerHTML = "女";
      sexSwitch.appendChild(dom1);
      this.img = this.imageList[0];
    },
    getageTxt() {
      var ageSwitch = document.getElementById("ageSwitch");
      var dom = document.createElement("span");
      dom.style = "font-size: 12px; margin:0 0 0 2px; color:white;";
      dom.className = "ageTxt";
      dom.innerHTML = "成人";
      var childerDiv = document.getElementById("childerDiv");
      childerDiv.style = "display:none";
      ageSwitch.appendChild(dom);
    },
    changeSex() {
      this.sexState = "正面";
      // console.log(this.sexState);
      var adultDiv = document.getElementById("adultDiv");
      var childerDiv = document.getElementById("childerDiv");
      //   childerDiv.style = "display:none";
      if (this.checked) {
        if (this.checked1) {
          this.img = this.imageList[0];
          childerDiv.style = "display:none";
          adultDiv.style = "display:block";
        } else {
          this.img = this.imageList[4];
          childerDiv.style = "display:block";
          adultDiv.style = "display:none";
        }
      } else {
        if (this.checked1) {
          this.img = this.imageList[2];
          childerDiv.style = "display:none";
          adultDiv.style = "display:block";
        } else {
          this.img = this.imageList[6];
          childerDiv.style = "display:block";
          adultDiv.style = "display:none";
        }
      }
    },
    changeAge() {
      this.sexState = "正面";
      // console.log(this.sexState);
      var ageText = "";
      var ageTxt = document.getElementsByClassName("ageTxt")[0];
      var ageSwitch = document.getElementById("ageSwitch");
      ageSwitch.removeChild(ageTxt);
      var dom = document.createElement("span");
      if (this.checked1) {
        ageText = "成人";
        dom.style = "font-size: 12px; margin:0 0 0 1px;color:white;";
      } else {
        ageText = "儿童";
        dom.style = "font-size: 12px; margin:0 0 0 24px;color:white;";
      }
      dom.className = "ageTxt";
      dom.innerHTML = ageText;
      ageSwitch.appendChild(dom);
      this.changeSex();
    },
    showPopup(index) {
      if (this.sexState == "正面") {
        this.show = true;
        if (this.checked)
          if (index == 0) {
            this.positionList = this.toubuList;
          } else if (index == 1) {
            this.positionList = this.xiongbuList;
          } else if (index == 2) {
            this.positionList = this.topfubuListMan;
          } else if (index == 3) {
            this.positionList = this.bottomfubuListMan;
          } else if (index == 4) {
            this.positionList = this.topziList;
          } else if (index == 5) {
            this.positionList = this.bottomList;
          } else {
            // this.$toast()
            console.log("请点击正确的部位");
          }
        else {
          if (index == 0) {
            this.positionList = this.toubuList;
          } else if (index == 1) {
            this.positionList = this.xiongbuList;
          } else if (index == 2) {
            this.positionList = this.topfubuListWoman;
          } else if (index == 3) {
            this.positionList = this.bottomfubuListWoman;
          } else if (index == 4) {
            this.positionList = this.topziList;
          } else if (index == 5) {
            this.positionList = this.bottomList;
          } else {
            // this.$toast()
            console.log("请点击正确的部位");
          }
        }
      } else {
        if (index == 0) {
          this.show = true;
          this.positionList = this.houtopbuList;
        } else if (index == 1 || index == 2 || index == 3) {
          this.$dialog
            .confirm({
              message:
                "抱歉没有此部位的智能导诊功能,是否直接跳转到科室列表进行挂号,或者返回到导诊?",
              confirmButtonText: "跳到科室",
              cancelButtonText: "返回",
            })
            .then(() => {
              // on confirm
              this.$router.replace({
                path: "/IDepList",
              });
            })
            .catch(() => {});
        } else if (index == 4) {
          this.show = true;
          this.positionList = this.topziList;
        } else if (index == 5) {
          this.show = true;
          this.positionList = this.bottomList;
        } else {
          // this.$toast()
          console.log("请点击正确的部位");
        }
      }
    },
    onChilderClick() {
      store.commit("store/setDepid", "0235");
      this.$router
        .replace({
          path: "/IDocList",
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onSymptom(depInfo) {
      var dep = JSON.stringify(depInfo);
      sessionStorage.setItem("depInfo", dep);
      this.$router
        .replace({
          path: "/IntelGuidl",
        })
        .catch((err) => {
          console.log(err);
        });
    },
    BackFace() {
      if (this.sexState == "正面") {
        this.sexState = "背面";
      } else {
        this.sexState = "正面";
      }
      // console.log(this.imgTip);
      if (this.checked) {
        if (this.checked1) {
          if (this.imgTip == 1) {
            this.img = this.imageList[0];
            this.imgTip = 0;
          } else {
            this.img = this.imageList[1];
            this.imgTip = 1;
          }
        } else {
          if (this.imgTip == 2) {
            this.img = this.imageList[4];
            this.imgTip = 0;
          } else {
            this.img = this.imageList[5];
            this.imgTip = 2;
          }
        }
      } else {
        if (this.checked1) {
          if (this.imgTip == 3) {
            this.img = this.imageList[2];
            this.imgTip = 0;
          } else {
            this.img = this.imageList[3];
            this.imgTip = 3;
          }
        } else {
          if (this.imgTip == 4) {
            this.img = this.imageList[6];
            this.imgTip = 0;
          } else {
            this.img = this.imageList[7];
            this.imgTip = 4;
          }
        }
      }
    },
  },
};
</script>

<style scoped>
</style>